import { fetch, post } from '@/utils/axios'

export default {
  list: params => {
    return fetch('/app/Users/profit', params)
  },
  info: () => {
    return fetch('/app/Users/profit/basic')
  },
  transformBalance: data => {
    return post('/app/Users/transformBalance', data)
  }
}
