<template>
  <div class="header">
    <van-row class="add-box" type="flex" justify="space-between">
      <van-col span="4" class="arrow-left">
        <div @click="back">
          <van-icon name="arrow-left" size="16" v-if="!noLeft" />
        </div>
      </van-col>
      <van-col span="16">
        <span>{{ totalName }}</span>
      </van-col>
      <van-col
        span="4"
        @click="goRouter"
        v-if="rightRouter"
        class="rightTitle"
        >{{ rightTitle }}</van-col
      >
      <van-col span="4" @click="handleClick" v-else class="rightTitle">{{
        rightTitle
      }}</van-col>
    </van-row>
  </div>
</template>
<script>
export default {
  props: {
    totalName: {
      type: [String],
      default: ''
    },
    noLeft: {
      type: [Boolean],
      default: false
    },
    routerName: {
      type: String,
      default: ''
    },
    rightTitle: {
      type: String,
      default: ''
    },
    rightRouter: {
      type: String,
      default: ''
    }
  },
  methods: {
    back() {
      if (this.routerName !== '') {
        this.$router.push({ name: this.routerName })
      } else {
        this.$router.go(-1)
      }
    },
    goRouter() {
      if (this.rightRouter) {
        this.$router.push({ name: this.rightRouter })
      }
    },
    handleClick() {
      this.$emit('clickRight')
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~@/styles/mixin.styl'
.header
  background-color #17181e
  position fixed
  left 0
  right 0
  top 0
  z-index 99
  font-size 16px
  border-bottom: 2.5px solid #000;
  height 48.5px
  color #fae39c
  .van-col
    height 48.5px
    flex-center()
  .rightTitle
    font-size 13px
  .arrow-left
    justify-content flex-start
    padding-left 16px
</style>
