<template>
  <div class="container">
    <PublicHeader
      :totalName="$t('profit').title"
      :noLeft="noLeft"
      :rightTitle="rightTitle"
      @clickRight="handleClickRight"
    />
    <div class="info-box">
      <p>{{ $t('profit').TotalAmount }}</p>
      <p class="amount">{{ basicConfig.Symbol }}{{ info.Amount }}</p>
      <div class="income-line">
        {{ $t('profit').YesterdayP }}
        <span class="income"
          >{{ basicConfig.Symbol }}{{ info.YesterdayAmount }}</span
        >
      </div>
      <div class="profit-box">
        <div class="profit-item">
          <p>{{ $t('profit').cumulativeIncome }}</p>
          <p>{{ info.FinancialPrifit }}</p>
        </div>
        <div class="profit-item">
          <p>{{ $t('profit').DailyInterest }}(%)</p>
          <p>{{ info.Interest }}</p>
        </div>
      </div>
      <div class="btn-box">
        <div class="btn" @click="handleClick(false)">
          {{ $t('profit').TransferIn }}
        </div>
        <div class="btn" @click="handleClick(true)">
          {{ $t('profit').TransferOut }}
        </div>
      </div>
    </div>
    <div class="title">{{ $t('profit').revenueRecord }}</div>
    <van-row class="nav">
      <van-col span="12"> {{ $t('product').Time }}</van-col>
      <van-col span="12">{{ $t('profit').SingleDay }}</van-col>
    </van-row>
    <van-pull-refresh
      v-model="refreshing"
      :pulling-text="$t('public').pullingText"
      :loosing-text="$t('public').ReleaseText"
      :loading-text="$t('public').loadingText"
      :success-text="$t('public').RefreshSucceeded"
      @refresh="onRefresh"
    >
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <van-row class="list" v-for="(item, index) in list" :key="index">
          <van-col span="12">{{ item.CreateTime | getLocalTime }}</van-col>
          <van-col span="12">{{ item.Amount }}</van-col>
        </van-row>
      </van-list>
    </van-pull-refresh>
    <TabBar :active="active" />
    <van-popup v-model="showInfo" position="bottom" :style="{ height: '80%' }">
      <div class="ql-editor info">
        <div v-html="basicConfig.FinanceProfile"></div>
      </div>
    </van-popup>
    <van-popup v-model="show" round closeable position="bottom">
      <div class="mode">
        {{
          transformFrom.ToBalance
            ? $t('profit').TransferOut
            : $t('profit').TransferIn
        }}
      </div>
      <van-field
        :border="false"
        v-model="transformFrom.Amount"
        @input="
          transformFrom.Amount = transformFrom.Amount.replace(/[^\d]/g, '')
        "
        :label="$t('profit').amount"
        :placeholder="$t('profit').placeholder"
      />
      <van-button type="info" round size="large" @click="handleTransform">{{
        $t('profit').confirm
      }}</van-button>
    </van-popup>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import TabBar from '@/components/tabBar'
import profitApi from '@/api/profit'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
    TabBar
  },
  data() {
    return {
      noLeft: true,
      showInfo: false,
      active: 'profit',
      rightTitle: this.$t('profit').synopsis,
      show: false,
      amount: null,
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      transformFrom: {
        ToBalance: false,
        Amount: null
      },
      info: {},
      form: {
        page: 1,
        size: 10
      }
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  methods: {
    handleClick(model) {
      if (this.show) {
        this.show = false
      } else {
        this.transformFrom.ToBalance = model
        this.show = true
      }
    },
    handleClickRight() {
      this.showInfo = true
    },
    async getInfo() {
      this.info = await profitApi.info()
    },
    async handleTransform() {
      let form = { ...this.transformFrom }
      form.Amount = Number(form.Amount)
      await profitApi.transformBalance(form)
      this.show = false
      this.getInfo()
      this.$toast(this.$t('profit').success)
      this.transformFrom.Amount = null
    },
    async getList() {
      const res = await profitApi.list(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }

      this.loading = false
    },
    onRefresh() {
      this.form.page = 1
      this.finished = false
      this.loading = true
      setTimeout(() => {
        this.list = []
        this.getList()
      }, 100)
    }
  },
  created() {
    this.getInfo()
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size 12px
  color #ebe5cd
  overflow-x hidden
  height 100vh
  padding 48.5px 0 0
  text-align center
  .info-box
    margin 15px
    border-radius 10px
    height 315px
    background-image: linear-gradient(#eecf6f,#f7e099,#fff0c1)
    color #222
    padding-top 43px
    .amount
      font-size  30px
      margin 12px 0 22px
    .income-line
      background: hsla(0,0%,96.1%,.49)
      color: #7c7a7a
      font-size 13px
      padding 7.5px 10px
      border-radius 18px
      display inline-block
      // flex-center()
      margin 0 auto
      .income
        color #f04d4d
        font-size 19px
        font-weight bold
        margin 0 5px
    .profit-box
      margin-top 50px
      height 46px
      display flex
      .profit-item
        flex 1
        flex-column()
        color #616060
        p:nth-of-type(2)
          font-size 17px
          font-weight bold
          color #000
          margin-top 5px
    .btn-box
      margin 15px 2px 0
      display flex
      .btn
        flex 1
        margin 0 5px
        height 40px
        flex-center()
        color #ffffff
        font-weight bold
        background #46a9fd
        border-radius 5px
        font-size 14px
        &:nth-of-type(2)
          background-color #ffba00
  .title
    border-bottom: 1Px solid #f2dc97
    color #f2dc97
    margin-top 30px
    padding-bottom 10px
    font-size 15px
  .nav
    color #aaaeb4
    font-size 12px
    line-height 26px
  .list-box
    height calc(100vh - 512px)
    overflow scroll
  .list
    color beige
    line-height 29px
  .van-popup
    background #f7f8fa
    text-align center
    .mode
      line-height 44px
      color #323232
      font-size 16px
  .van-button
    width calc(100% - 30px)
    box-sizing border-box
    margin 21px 15px 23px
.info
  background #1f1f1f
  color #ffffff
</style>
