<template>
  <van-tabbar
    v-model="childActive"
    active-color="#f2dc97"
    :border="false"
    :style="{ background: bg }"
  >
    <van-tabbar-item
      v-for="(item, index) in list"
      :key="item.icon"
      :name="item.name"
      :icon="item.icon"
      :to="item.to"
      :url="item.url"
      @click="handleClick(item.to)"
      >{{ tabBar[index] }}</van-tabbar-item
    >
  </van-tabbar>
</template>

<script>
import { mapGetters } from 'vuex'
const fullList = [
  {
    name: 'index',
    icon: 'refund-o',
    to: 'Product'
  },
  {
    name: 'profit',
    icon: 'balance-pay',
    to: 'Profit'
  },
  {
    name: 'service',
    icon: 'chat-o',
    to: ''
  },
  {
    name: 'new',
    icon: 'fire-o',
    to: 'New'
  },
  {
    name: 'me',
    icon: 'manager-o',
    to: 'Me'
  }
]
export default {
  props: {
    active: {
      type: String,
      default: 'index'
    },
    bg: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
    list() {
      fullList[2].url = this.basicConfig.ServiceUrl
      let List = [...fullList]
      if (!this.basicConfig.IsOpenIncome) {
        List.splice(1, 1)
        return List
      } else {
        return List
      }
    },
    tabBar() {
      let tabBar = [...this.$t('tabBar')]
      if (!this.basicConfig.IsOpenIncome) {
        tabBar.splice(1, 1)
        return tabBar
      } else {
        return tabBar
      }
    }
  },
  data() {
    return {
      childActive: this.active
    }
  },
  methods: {
    handleClick(to) {
      if (this.$route.name === to) {
        window.location.reload()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.van-tabbar
  background: #201e1e;
  >>> .van-tabbar-item--active
    background: none;
</style>
